
const constant = {
    websiteName:'FairBaazi',
    baseUrl: 'https://fbcricxyz.in/',
    whatsAppNumber:'+919931379051',
    instagramUrl:'https://www.instagram.com/fairbaazi/',
    telegramUrl:'https://api.whatsapp.com/send/?phone=919931379051text&type=phone_number&app_absent=0',
    youTubeUrl:'https://www.youtube.com/channel/UCcaunrHXS3IK8UEhQv34uIQ',
    apkUrl:'https://baazi-cdn.sgp1.cdn.digitaloceanspaces.com/fairbaazi/FairBaazi.apk',
    landingPageLogoSize:'70px',
    logoPath:"logo.jpeg",

    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createId: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest'
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    }
}

export default constant;