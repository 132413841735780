export const userNameValidation = name => {
  const regex = new RegExp('^[a-zA-Z0-9]{4,12}$');
  return regex.test(name);
};
export const nameValidation = name => {
  const regex = new RegExp('^[a-zA-Z0-9, ]{2,20}$');
  return regex.test(name);
};

export const phoneNumberValidation = phoneNumber => {
  const regex = new RegExp('^[0-9]{10}$');
  return regex.test(phoneNumber);
}

export const accountNumberValidation = accounNumber => {
  const regex = new RegExp('^[0-9]{9,20}$');
  return regex.test(accounNumber);
}

export const upiIdRegexValidation = upiId => {
  const regex = new RegExp('^[a-zA-Z0-9.-_]{2,49}@[a-zA-Z._]{2,49}$');
  return regex.test(upiId);
}

export const ifscCodeValidation = ifscCode => {
  const regex = new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$');
  return regex.test(ifscCode);
}

export const passwordValidation = phoneNumber => {
  const regex = new RegExp('^.{5,35}$');
  return regex.test(phoneNumber);
}

export const dateConvert = value => {
  const selectedDate = new Date(value)
  const date = selectedDate.toDateString()
  const time = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(selectedDate)
  return `${date} ${time}`;
}

export const timeDifference = (firstDate, secondDate) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);

  const diff = date2.getTime() - date1.getTime();

  let msec = diff;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  msec -= ss * 1000;

  const hrText = hh > 1 ? 'hrs' : 'hr';
  const minText = mm > 1 ? 'mins' : 'min';
  const secText = ss > 1 ? 'secs' : 'sec';

  return `${hh}${hrText} ${mm}${minText} ${ss}${secText}`
}